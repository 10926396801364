/* ==========================================================================
   Geral
   ========================================================================== */

body{
    background-color: white;
    color: $dark;
    font-family: 'bradescosans-regular',sans-serif;
}

.c-cinza{
    color: #3C3D3E !important;
}

.container {

    @media(max-width: 380px) {
        padding-left: 16px;
        padding-right: 16px;
    }

    @media (min-width: 381px) and (max-width: 991px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media(min-width: 1200px) {
        max-width: 1154px;
    }
}

.container-mobile-only {
    @media(min-width: 992px) {
        max-width: initial;
        padding: 0;
    }
}

.container-desktop-only {
    @media(max-width: 991px) {
        max-width: initial;
        padding: 0;
    }
}

.row-desktop-only {
    @media(max-width: 991px) {
       margin: 0;
       padding: 0;

       .col-12 {
            padding: 0;
        }
    }
}

.underline {
    text-decoration: underline;
}

.c-vermelho{ color: $vermelho; }

.bcg-cinza { background-color: $cinza-escuro; }
.bcg-azul { background-color: $azul-claro; }
.bcg-vermelho { background-color: $vermelho; }

.fw-700 { font-weight: 700; }

.mt-32 {margin-top: 32px;}

.mt-40 {margin-top: 40px;}

.titulo{
    font-family: 'bradescosans-bold',sans-serif;
    margin-bottom: 0;
    font-weight: 400;

    &.h2{
        font-size: 24px;
        line-height: 120%;

        @media (min-width:992px){
            font-size: 32px;
            line-height: 150%;
        }
    }

    &.h5{
        font-size: 14px;
        line-height: 140%;
    }
}


.cards_sect {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column-reverse;
    font-family: 'bradescosans-regular',sans-serif;
    overflow: hidden;

    @media (min-width: 992px) {
        flex-direction: column;
        padding-bottom: 24px;
    }

    &.padding-top {
        @media(min-width: 992px){
            padding-top: 80px;
        }
    }

    .filtros_wrapper {
        padding: 27px 0;

        @media (min-width: 992px) {
            padding-top: 40px;
            padding-bottom: 36px;
        }
    }
}

.cards_sect-capitalizacao {
    padding: 0 0 24px;

    @media(min-width: 992px) {
        padding: 43px 0;
    }
}

.carousel-cards {
    margin-top: 24px;
    overflow: visible !important;

    @media (min-width: 992px) {
        margin-top: 0;
        overflow: hidden !important;
        padding: 15px !important;
        margin: -15px !important;

    }

    @media (min-width: 992px) {
        height: 455px;
    }

    .swiper-slide {
        width: 310px;

        @media (min-width: 992px) {
            max-width: 275px;
        }
    }

    .swiper-pagination {
        display: none;

        @media (min-width: 992px) {
            display: block;
            padding-top: 30px;
            padding-bottom: 25px;
        }
    }

    .swiper-pagination .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: #BCBCBE;
        opacity: 1;
    }

    .swiper-pagination .swiper-pagination-bullet-active {
        background-color: #CC092F;
    }

    .swiper-pagination.swiper-pagination_azul  .swiper-pagination-bullet-active {
        background-color: #006CA9;
    }
}

.area-link {
    margin: 0 auto;

    @media (min-width: 992px) {
        position: absolute;
        bottom: 35px;
        right: 16px;
        z-index: 100;
        margin: 0;
    }

    .link-vermelho {
        font-size: 16px;
        color: #CC092F;
        font-weight: 500;
        text-decoration: none;
        margin-bottom: 19px;
        margin-top: 10px;

        @media (min-width: 992px) {
            margin-bottom: 0;
            margin-top: 0;
        }

        &:hover {
            color: darken(#CC092F, 10%);
            text-decoration: underline;
        }
    }

    .link-azul {
        font-size: 16px;
        color: #006CA9;
        font-weight: 500;
        text-decoration: none;
        margin-bottom: 19px;
        margin-top: 10px;

        @media (min-width: 992px) {
            margin-bottom: 0;
            margin-top: 0;
        }

        &:hover {
            color: darken(#006CA9, 10%);
            text-decoration: underline;
        }
    }
}

.chamadas_sect {
    font-family: 'bradescosans-regular',sans-serif;
    padding-top: 58px;

    @media (min-width: 992px){
      padding-top: 80px;
    }
    .chamadas-card_overflow {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        padding: 0 24px;
        margin: 0 -24px;

        @media(min-width: 576px) {
            margin: 0;
            padding: 0;
        }

        @media(min-width: 992px) {
            overflow: visible;
            flex-wrap: wrap;
        }

        .col-12 {
            @media(max-width: 991px) {
                width: 86%;
                margin-right: 16px;
                max-width: 320px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
.sect_clubecompleto {
    overflow: hidden;
    display: flex;
    align-items: center;
    font-family: 'bradescosans-regular',sans-serif;
    padding: 8px 0 0 0;

    @media (min-width: 992px) {
        padding: 80px 0;
    }

    .clube-img {
        border-radius: 10px;
        margin-bottom: 24px;

        @media (min-width: 992px) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .titulo {
        margin-bottom: 33px;
        text-align: center;
        font-size: 32px;
        line-height: 48px;
        font-weight: 500;
        margin-top: 48px;
        font-family: 'bradescosans-regular',sans-serif;

        @media (min-width: 992px) {
            text-align: left;
            font-size: 32px;
            margin-top: 0;
        }
    }

    .clube-text {
        font-size: 16px;
        line-height: 19px;
        color: #262626;
        margin-bottom: 8px;
        font-weight: 700;

        @media (min-width: 992px) {
            font-size: 18px;
            line-height: 150%;
            margin-bottom: 27px;
            font-weight: 400;
        }



        &:last-of-type {
            margin-bottom: 0;
        }

    }

    .icones {
        font-size: 56px;
        color: #D90037;
        margin-right: 32px;

        @media (min-width: 992px) {
            font-size: 26px;
            margin-right: 18px;
        }
    }

    .icone-flores-presentes {
        margin-right: 32px;

        @media (min-width: 992px) {
            margin-right: 16px;
            width: 32px;
            height: 32px;
            margin-left: 20px;
        }
    }

    .icone-cashback {
        margin-right: 32px;

        @media (min-width: 992px) {
            margin-right: 16px;
            width: 32px;
            height: 32px;
            margin-left: 20px;
        }
    }

    .icone-protecao-familia {
        margin-right: 32px;

        @media (min-width: 992px) {
            margin-right: 16px;
            width: 32px;
            height: 32px;
            margin-left: 20px;
        }
    }

    .club-text-mobile {
        font-size: 14px;
        line-height: 17px;
        color: #262626;
        margin-bottom: 0;
    }

    .box-mobile {
        border-bottom: 1px solid #F3F3F3;
        display: flex;
        align-items: center;
        padding-bottom: 32px;
        margin-top: 26px;

        &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
        }

        &:first-of-type {
            margin-top: 0;
        }
    }
}

.sect_simplesdeusar {
    padding-bottom: 64px;
    font-family: 'bradescosans-regular',sans-serif;

    @media (min-width: 992px) {
        padding-bottom: 100px;
    }

    .titulo {
        font-family: 'bradescosans-regular',sans-serif;
        font-size: 32px;
        line-height: 48px;
    }

    .box-simples {
        display: flex;
        margin-top: 36px;

        @media (min-width: 992px) {
            margin-top: 48px;
        }
    }

    .numero {
        font-weight: 700;
        font-size: 32px;
        line-height: 100%;
        text-align: center;
        color: #D90037;
        margin-right: 30px;

        @media (min-width: 992px) {
            margin-right: 16px;
        }
    }

    .titulo-simples {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #191919;
        margin-bottom: 8px;
    }

    .texto-simples {
        font-weight: 400;
        color: #333333;
        margin-bottom: 0;
        font-size: 13px;
        line-height: 140%;

        @media (min-width: 992px) {
            font-size: 14px;
        }
    }
}

.sect_beneficios {
    overflow: hidden;
    padding-top: 32px;
    // padding-bottom: 48px;
    font-family: 'bradescosans-regular',sans-serif;

    @media (min-width: 992px) {
        // padding-top: 64px;
        // padding-bottom: 118px;
    }

    .titulo {
        font-family: 'bradescosans-regular',sans-serif;
        text-align: center;
        margin: 0 20px;

        @media (min-width: 992px) {
            text-align: left;
            margin: 0 45px 0 0;
        }
    }

    .beneficios {
        margin-top: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (min-width: 992px) {
            margin-top: 0;
            align-items: flex-start;
        }
    }

    .beneficios-texto {
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        color: #262626;
        margin: 16px 20px 32px;

        @media (min-width: 992px) {
            font-size: 18px;
            line-height: 150%;
            text-align: left;
            margin: 16px 60px 40px 0px;;
        }
    }

    .btn {
        width: 290px;
    }
}

.default-animation {
    transform: translateY(100px);
    opacity: 0;
  }

  .left-in-animation {
    transform: translateY(100px);
    opacity: 0;

    @media(min-width: 992px) {
        transform: translateX(-100px);
        opacity: 0;
    }
  }

  .right-in-animation {
    transform: translateY(100px);
    opacity: 0;

    @media(min-width: 992px) {
        transform: translateX(100px);
        opacity: 0;
    }
  }

.animated-item {
    transition-duration: 1s;
    transition-timing-function: ease;

    &.in {
      transform: translateY(0);
      opacity: 1;
    }
  }

.cadastro_sect {
    overflow: hidden;
    position: relative;

    .container {
        display: flex;
        align-items: flex-end;
        width: 100%;
        min-height: calc(100vh - 64px);

        @media(min-width: 992px) {
            max-height: calc(100vh - 72px);
            min-height: calc(100vh - 72px);
            overflow: auto;
            align-items: stretch;
            position: relative;
        }
    }

    .direitos-reservados_text {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 12px;
        line-height: 15px;
        color: #3C3D3E;
        padding: 8px 0 9px;
        margin-bottom: 0;
        background-color: #FAFAFA;
        text-align: center;


        @media(min-width: 992px) {
            padding: 12px 0;
            font-size: 14px;
            line-height: 17px;
            background-color: transparent;
            text-align: initial;
            left: 0;
            right: initial;
        }
    }

    .left-side {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: black;
        padding-top: 32px;
        padding-bottom: 72px;
        height: 100%;
        min-height: calc(100vh - 64px);

        @media(min-width: 992px) {
            display: block;
            position: relative;
            padding-top: 60px;
            max-width: 402px;
            min-width: 402px;
            min-height: calc(100vh - 72px);

            &--wider {
                max-width: 466px;
                min-width: 466px;
            }
        }

        .intro {
            margin: 24px 0 18px;

            @media(min-width: 992px) {
                margin: 24px 0 32px;
            }

            .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 120%;
            }
            .subtitle{
                font-size: 18px;
                margin-bottom: 16px;
                font-weight: 700;
            }

            .text {
                font-size: 14px;
                line-height: 120%;
                margin-bottom: 0;
                margin-top: 8px;
            }

            .intro-header {
                display: flex;
                align-items: center;

                .intro-steps {
                    font-size: 14px;
                    line-height: 120%;
                    color: #6D6E71;
                    margin: 0 0 0 11px;
                }
            }
        }

        .cadastro-filtros {
            margin-bottom: 24px;

            @media(min-width: 992px) {
                margin-bottom: 32px;
            }
        }

        .form_title {
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            margin-bottom: 14px;

            @media(min-width: 992px) {
                margin-bottom: 16px;
            }
        }


        &.left-side-pix{
            padding-top: 32px;

            @media(min-width: 992px) {
                max-width: 480px;
                padding-top: 107px;
            }
            @media(min-width: 1200px){
                min-width: 600px;
            }

            .intro {

                .title {
                    margin-bottom: 16px;

                    @media (min-width: 992px) {
                        margin-bottom: 8px;
                    }
                }

                .subtitle {
                    line-height: 120%;

                    @media (min-width: 992px) {
                        line-height: 160%;
                    }

                }
            }

            .login-chave-pix{
   
                .inputRadioWrapper{
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    margin-bottom: 32px;

                    @media(min-width: 992px) {
                        flex-direction: row;
                        gap: 40px;
                    }
                }
            }

            .mb-40{
                margin-bottom: 32px;

                @media(min-width: 992px) {
                    margin-bottom: 40px;
                }
            }

            .fs-12{font-size: 12px;}

            .form-check-label{
                font-size: 16px;
            }

            .input-form-group{
                width: 222px;

                @media(min-width: 1200px) {
                    width: 276px;
                }
            }

            .btn.btn-vermelho{
                width: 100%;

                @media(min-width: 992px) {
                    width: 276px;
                }
            }
            .btn.btn-azul-100{
                width: 100%;

                @media(min-width: 992px) {
                    width: 276px;
                }
            }

            &.mediservice{

                .input-form-group{
                    width: 100%;
    
                    @media(min-width: 1200px) {
                        width: 276px;
                    }
                }
            }
        }

        &.left-side-sucesso{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            @media(min-width: 992px) {
               align-items: flex-start;
            }

            .intro{
                text-align: center;

                @media(min-width: 992px) {
                    text-align: left;
                }

                .pix-sucesso {
                    color: #000;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 22px;

                    @media (min-width: 992px) {
                        margin-bottom: 32px;
                        max-width: 329px;
                    }
                }
            }

            .img-pix-sucesso{
                width: 64px;
                margin-bottom: 24px;

                @media(min-width: 992px) {
                    margin-bottom: 16px;
                 }
            }
            .detalhe{
                font-size: 14px;
                color: #6D6E71;
            }

            .btn-continuar{
                width: 100%;
                display: inline-flex;
                position: absolute;
                left: 0;
                bottom: 64px;
                align-self: flex-end;

                @media(min-width: 992px) {
                    display: none;
                }
            }
        }
        
    }

    .sucesso-left-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: inherit;
        margin: 0 auto;

        @media(min-width: 992px) {
            margin: initial;
        }

        .sucesso_wrapper {
            margin: 0 auto;
            text-align: center;

            @media(min-width: 992px) {
                text-align: initial;
            }

            .icone {
                width: 80px;
                margin-bottom: 32px;

                @media(min-width: 992px) {
                    width: 64px;
                    margin-bottom: 14px;
                }
            }

            .success_title {
                font-weight: 600;
                font-size: 24px;
                line-height: 120%;
                margin-bottom: 16px;

                @media(min-width: 992px) {
                    margin-bottom: 16px;
                    font-size: 32px;
                }
            }

            .success_text {
                margin-bottom: 0;
                font-size: 14px;
                line-height: 120%;
                color: #6D6E71;
            }
        }
    }

    .container-sucesso {
        align-items: center;
    }

    .right-side {
        display: none;
        align-items: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        height: calc(100vh - 72px);
        width: 43%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none;
        padding: 0 71px 91px;

        @media(min-width: 992px) {
            display: flex;
        }

        .image_text {
            position: relative;
            z-index: 2;
            font-size: 30px;
            line-height: 130%;
            color: #FFFFFF;
            margin-bottom: 0;
            white-space: nowrap;
        }

        .flutuante {
            position: absolute;
            left: 42px;

            @media(min-width: 992px) {
                max-width: 150px;
                bottom: 190px;
            }

            @media(min-width: 1400px) {
                max-width: initial;
                bottom: 170px;
            }

            @media(min-width: 992px) and (max-height: 650px) {
                max-width: 150px;
                bottom: 190px;
            }
        }

        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 40%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
            mix-blend-mode: multiply;
            opacity: 0.5;
        }
    }

    &.acessar-v2{
        min-height: 600px;

        .left-side{
            display: flex;
            justify-content: flex-end;
             position: relative;

             @media(min-width: 992px){
                justify-content: center;  
             }

            .voltar_link{
                position: absolute;
                top: 32px;
                left: 0;

                @media(min-width: 992px) {
                    top: 72px;
                }
            }

            @media (max-height: 650px) {
                padding-top: 65px;
            }
    
            @media (min-width: 992px) and (max-height: 650px) {
                padding-top: 110px;
            }
        }
        .right-side{
            width: 47%;
            background-position: top left;
        }

        .direitos-reservados_text{
            @media(max-width: 992px) {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
            }
        }

        .info-acesso-v2 {
            margin-top: 24px;
            
            .titulo {
                color: #000;
                font-size: 14px;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 8px;
            }

            .texto {
                color: #555658;
                font-size: 12px;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 0;

                @media (min-width: 992px) {
                    font-size: 14px;
                }
            }
        }
    }

    &.cadastro_sect_v2{
        min-height: 600px;
        padding-top: 0;

        .left-side{
            display: flex;
            justify-content: center;
             position: relative;
             padding-top: 32px ;
             
             @media(min-width: 992px) {
                padding-top: 72px;
                justify-content: flex-start;
             }

          
        }
        .right-side{
            width: 47%;
            background-position: top left;
        }

        .direitos-reservados_text{
            @media(max-width: 992px) {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
            }
        }
    }
}

.mb-16-32{
    margin-bottom: 16px;

    @media(min-width: 992px) {
        margin-bottom: 32px;
    }
}

.biometria_sect {
    .container {
        .left-side {
            justify-content: flex-end;

            .intro {
                text-align: center;
                margin-bottom: 100px;

                .biometria_image {
                    display: block;
                    margin: 0 auto 24px;
                }

                .title {
                    margin-bottom: 16px;
                }

                .text {
                    font-size: 14px;
                    margin: 0;
                    color: #6D6E71;
                }
            }

            .bottom_holder {
                .link-vermelho {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 51px;
                    font-size: 16px;
                }
            }
        }
    }
}

.voltar_link {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #555658;

    &:hover {
        .icones {
            transform: translateX(-5px);
        }
    }

    .icones {
        display: inline-block;
        margin-right: 6px;
        transition: 0.4s ease;
    }
}




    .form-control{
        width: 100%;
        height: 47px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 16px;
        gap: 10px;
        border: 1px solid #A2A2A5;
        border-radius: 5px;

        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #262626;


        &::placeholder{
            color: #A2A2A5;
        }

        &:focus{
            outline: none;
        }
      }

      .input-password {
        padding-right: 44px;
      }

      .btn-password{
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        background-color: transparent;
        outline: none;
        padding-left: 10px;
        padding-right: 16px;
        height: 100%;
      }

      .form-check-input{
        width: 20px;
        height: 20px;
        border: 1px solid #A2A2A5;
        border-radius: 5px;
        transition: all .3s ease;

        &:checked{
            background-color: #CC092F;
        }

        &:focus{
            box-shadow: 0 0 0 0.25rem rgba(204, 9, 48, 0.25);
            border: 0;
        }

        &_azul{
            &:checked{
                background-color: #006CA9;
            }
            &:focus{
                box-shadow: 0 0 0 0.25rem rgba(0, 107, 169, 0.25);
                border: 0;
            }
        }
      }
      .form-check-label{
        font-weight: 400;
        line-height: 120%;
        font-size: 14px;
        margin-left: 4px;
      }


      .btn{border-radius: 5px;}



.seguro_disclaimer {
    text-align: center;
    font-size: 12px;
    line-height: 120%;
    color: #A2A2A5;
    margin: 8px 0 0;
}

.link-vermelho{
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-decoration-line: underline;
    color: #CC092F;

    &:hover{
        text-decoration-line: none;
    }
}

.link-azul{
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-decoration-line: underline;
    color: #006CA9;

    &:hover{
        text-decoration-line: none;
    }
}

.login-filtros {
    display: flex;
    flex-wrap: nowrap;
    border: 0;
    overflow-x: auto;
    padding: 5px;
    background: #F4F4F4;
    border-radius: 5px;
    gap: 10px;

    .filtro_link {
        width: 50%;
        cursor: pointer;
        text-align: center;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: #3C3D3E;
        padding: 8px 12px;
        white-space: nowrap;
        text-decoration: none;
        transition: 0.4s ease;

        &.active{
            background-color: #CC092F;
            color: white;
        }
    }

    &.azul{
        .filtro_link{
            &.active{
                background-color: #006CA9;
                color: white;
            }
        }
    }
}

.animated-fade-in{
    animation: fadeIn ease 0.6s;
}

@keyframes fadeIn {
    0%{
        transform: translateX(120px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}


.interna-categoria_sect {
    padding: 24px 0 80px;

    .categoria_icon {
        width: 56px;
        min-width: 56px;

        @media (min-width: 992px) {
            width: 48px;
            min-width: 48px;
        }
    }

    .categoria_intro {

        .categoria-label_wrapper {
            display: flex;
            align-items: center;

            .categoria_label {
                font-size: 14px;
                line-height: 150%;
                color: #4F4F4F;
                margin-bottom: 0;
                margin-right: 16px;
            }
        }

        .categoria-text_wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            @media (min-width: 992px) {
                margin-bottom: 32px;
            }

            .categoria_name {
                font-weight: 700;
                font-size: 32px;
                line-height: 140%;
                color: #262626;
                margin-bottom: 0;
                margin-right: 20px;

                @media(min-width: 992px) {
                    margin-right: 0;
                    line-height: 100%;
                }
            }
        }
    }

    .categoria-aside_menu {

        @media(max-width: 991px) {
            position: fixed;
            z-index: 10;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: white;
            padding: 48px 24px;
            overflow: auto;
            transform: translateX(-100%);
            transition: 0.6s ease;
        }

        .close-categoria-aside_btn {
            background-color: transparent;
            border: 0;
            outline: none;
            color: #0061AA;
            margin-bottom: 24px;
            font-size: 21px;
            text-decoration: none;

            @media(min-width: 992px) {
                display: none;
            }
        }

        .filtros_text {
            font-weight: 700;
            font-size: 32px;
            line-height: 100%;
            color: #262626;
            margin-bottom: 24px;

            @media(min-width: 992px) {
                font-weight: 400;
                font-size: 18px;
                line-height: 150%;
                color: #4F4F4F;
                margin-bottom: 16px;
            }
        }

        .filtro_title {
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            color: #262626;
            margin-bottom: 24px;
        }

        .filtro_holder {          
            margin-bottom: 40px;            

            @media(min-width: 992px){
                padding-bottom: 24px;
                margin-bottom: 24px;   
                          
            }

            &.bordered {
                @media(min-width: 992px){
                    border-bottom: 1px solid #EDEDED; 
                }
            }

            .scroll_handle {             
                max-height: 186px;
                overflow: auto;
                padding-right: 16px;

                &::-webkit-scrollbar {
                    width: 5px;   
                    
                    @media(min-width: 992px){
                        width: 8px;   
                    }
                  }
                  
                  &::-webkit-scrollbar-track {
                    background: #F7F7F7;        
                  }
                  
                  &::-webkit-scrollbar-thumb {
                    background-color: #D5D6D7;    
                    border-radius: 20px;   
                  }
            }

            .input-search_wrapper {
                position: relative;
                margin-bottom: 16px;

                .icone-lupa {
                    position: absolute;
                    pointer-events: none;
                    z-index: 2;
                    right: 13px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .form-control {
                    padding-right: 40px;
                }
            }

            .form-check {
                padding-left: 28px;
                @media(max-width: 991px){
                    position: relative;
                    padding-left: 0;
                }

                .form-check-input {
                    @media(max-width: 991px){
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        &.in {
            transform: translateX(0);
        }
    }

    .result_intro {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        @media(min-width: 992px) {
            margin-bottom: 21px;
        }

        .result-counter_text {
            font-size: 18px;
            line-height: 150%;
            color: #4F4F4F;
            margin-bottom: 0;
        }

        .ordenar-select_wrapper {
            width: 178px;
            display: none;

            @media(min-width: 992px) {
                display: block;
            }
        }

        .filtro-ordenar-mobile_wrapper {
            @media(min-width: 992px) {
                display: none;
            }

            .link-blue {
                font-size: 16px;
                line-height: 150%;
                color: #2D9DE9;
                text-decoration: none;

                .filtro_counter {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    min-width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: #2D9DE9;
                    color: white;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }
            }

            .links-division {
                display: inline-block;
                font-size: 16px;
                line-height: 150%;
                color: #A2A2A5;
                margin: 0 10px;
            }
        }
    }

    .categoria-cards_wrapper {

        .card {
            max-width: initial;
            margin-bottom: 24px;
        }
    }
}

.float-padrao {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 48px 24px 32px;
    transform: translateX(-100%);
    transition: 0.6s ease;
    background-color: white;

    @media (min-width: 1200px){
        display: none;
    }

    .top_content {
        margin-bottom: 32px;

        .btn-close-float {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 0;
            outline: none;
            color: #0061AA;
            margin-bottom: 24px;

            .icones {
                font-size: 21px;
            }
        }

        .title {
            font-weight: 700;
            font-size: 32px;
            line-height: 100%;
            color: #262626;
            margin-bottom: 32px;
        }
    }

    &.in {
        transform: translateX(0);
    }
}

.detalhe-oferta {
    padding-bottom: 80px;
    position: relative;

    @media (min-width: 992px) {
        padding-top: 32px;
    }

    

    .container-detalhe {

        @media (min-width: 992px) {
            max-width: 960px;
            margin: auto;
            display: flex;
        }

        @media (min-width: 1200px) {
            max-width: 1154px;
        }

        .detalhe-oferta-area-img {

            .area-info-divul {
                margin-top: 24px;
                display: none;

                @media (min-width: 992px) {
                    display: flex;
                    justify-content: space-between;
                }

                .area-divulgacao {
                    display: flex;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 0;
            
                    .btn-favoritar-oferta {
                        background-color: transparent;
                        outline: none;
                        border: 0;
                        padding: 0;
            
                        .icone-coracao {
                            display: block;
                            max-width: 20px;
                            min-width: 20px;
                        }
            
                        .icone-coracao-fav {
                            display: none;
                            max-width: 20px;
                            min-width: 20px;
                        }
            
                        &.favoritada {
                            .icone-coracao {
                                display: none;
                            }
            
                            .icone-coracao-fav {
                                display: block;
                            }
                        }
            
                        &:disabled {
                            .icone-coracao {
                                color: #D5D6D7;
                            }
                        }
                    }
            
                    .divulgacao_acoes {
                        .link-divulgacao-padrao {
                            display: inline-flex;
                            margin-right: 24px;
                            max-height: 18px;
                            min-height: 18px;
                        }
                        .icones {
                            font-size: 18px;
                            cursor: pointer;
                        }
            
                        .icone-coracao-fav {
                            color: #F30347;
                        }
            
                        .icone-email {
                            font-size: 16px;
                        }
                    }
                }

                .detalhe-data {
                    margin-bottom: 0;

                    .valido {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 150%;
                    }
                }
            }
        }
    }

    &-area-img {
        width: 100%;

        @media(min-width: 992px){
            width: initial;
        }       

        .container {
            padding: 0;

            @media (min-width: 992px) {
                padding: initial;
            }
        }       

    }

    .content_holder {
        @media(min-width: 992px){
            display: flex;
            gap: 51px;
        }

        .detalhe-oferta-area-img {
            .area-info-divul {
                display: flex;              
                justify-content: space-between;    
                align-items: flex-start;            
                margin-bottom: 24px;

                @media(min-width: 992px){
                    flex-direction: row-reverse;
                    align-items: center;
                    margin-bottom: 0;
                }
    
                .area-divulgacao {
                  margin: 0;
                }

                .detalhe-data {
                    margin: 0;

                    .valido {                 
                        font-size: 12px;                       
                        line-height: 150%; 
                        max-width: 122px;
                        text-align: right;

                        @media(min-width: 992px){
                            max-width: initial;
                            text-align: initial;
                        }
                    }
                }
            }
        }

        &-novo{
            gap: 32px;
       
            .texto-topo{
                display: flex;
                gap: 40px;
                color: #4F4F4F;
                margin-bottom: 8px;
            }

            .titulo{
                font-size: 32px;
                line-height: 150%;
            }
            .detalhe-oferta-img{
                @media (min-width: 992px) {
                    width: 562px;
                    height: 390px;

                    &::after {
                        content: '';
                        display: block;
                        padding-bottom: 65%;
                    }  
                } 
                
                .detalhe-oferta-img-circulo{
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    top: 24px;
                    left: 24px;
                    background-color: white;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    &-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
        position: relative;               
        margin: 0 -24px 20px;

        &::after {
            content: '';
            display: block;
            padding-bottom: 59%;
        }        

        @media (min-width: 992px) {
            border-radius: 20px;

            width: 420px;
            height: 250px;
            margin: 0 0 16px;

            &::after {
                display: none;
            }
        }        

        &-retangulo {
            overflow: hidden;
            position: absolute;
            width: 64px;
            height: 64px;
            border-radius: 5px;
            border: 2px solid #FFF;           
            display: flex;
            justify-content: center;
            align-items: center;
            top: 24px;
            left: 24px;          

            @media (min-width: 992px) {           
                width: 72px;
                height: 72px;             
            }
        }
    }

    &-area {        
    }

    .nav-oferta {
        margin-bottom: 8px;
        transition: all ease-in-out .4s;

        @media(min-width: 992px) {
            margin-bottom: 42px;
        }

        &-link {
            font-size: 14px;
            line-height: 150%;
            color: #2D9DE9;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: darken(#2D9DE9, 10%);
            }

            @media (min-width: 992px) {
                font-size: 16px;
                line-height: 150%;
            }
        }

        .icone-chevron-right {
            font-size: 10px;
            opacity: 0.3;
            margin: 0 16px;
        }
    }

    .info-detalhe {
        font-size: 16px;
        line-height: 150%;
        color: #262626;
        margin-top: 8px;
        margin-bottom: 24px;

        @media (min-width: 992px) {
            font-size: 20px;
            margin-top: 8px;
            margin-bottom: 16px;
            margin-right: 10px;
        }
    }

    .area-divulgacao {
        display: flex;
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 992px) {
            margin-top: 0;
            justify-content: flex-start;
        }

        .btn-favoritar-oferta {
            background-color: transparent;
            outline: none;
            border: 0;
            padding: 0;

            .icone-coracao {
                display: block;
                max-width: 20px;
                min-width: 20px;
            }

            .icone-coracao-fav {
                display: none;
                max-width: 20px;
                min-width: 20px;
            }

            &.favoritada {
                .icone-coracao {
                    display: none;
                }

                .icone-coracao-fav {
                    display: block;
                }
            }

            &:disabled {
                .icone-coracao {
                    color: #D5D6D7;
                }
            }
        }

        .card-tag {
            margin-bottom: 0 !important;
            font-size: 11px;
            line-height: 13px;

            @media (min-width: 992px) {
                margin-right: 16px;
            }
        }

        .tag-vermelha {
            font-size: 12px;
            padding: 3px 16px 3px 12px;

            .card-tag-texto {
                margin-left: 8px;
            }
        }

        .divulgacao_acoes {
            .link-divulgacao-padrao {
                display: inline-flex;
                margin-right: 24px;
                max-height: 18px;
                min-height: 18px;
            }
            .icones {
                font-size: 18px;
                cursor: pointer;
            }

            .icone-coracao-fav {
                color: #F30347;
            }

            .icone-email {
                font-size: 16px;
            }
        }
    }

    .area-tag {
        display: flex;
        justify-content: flex-start;

        .card-tag {
            margin-right: 8px;

            @media (min-width: 992px) {
                margin-right: 16px;
            }
        }
    }

    .mb-32 {
        margin-bottom: 32px;
    }

    .detalhe-data {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        @media (min-width: 992px) {
            margin-bottom: 32px;
        }

        .icon_holder {
            background-color: #F5F5F5;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            margin-right: 16px;
        }

        .valido {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 150%;
            color: #4F4F4F;
        }

        .link-vermelho {
            font-size: 16px;
            line-height: 150%;
        }
    }

    .detalhe-data-mobile {
        margin-bottom: 0;
        max-width: 122px;

        @media (min-width: 560px) {
            max-width: fit-content;
        }

        .valido {
            font-size: 11px;
            text-align: right;
        }
    }

    .area-input {
        display: flex;

        .select-estado {
            width: 80px;
            margin-right: 16px;

            &:disabled {
                color: #262626;
            }
        }

        .select-cidade {
            width: 273px;

            &:disabled {
                color: #B3B3B3;
            }
        }

        .select-loja {
            width: 369px;

            &:disabled {
                color: #B3B3B3;
            }
        }

        .select-unidade {
            width: 273px;

            &:disabled {
                color: #B3B3B3;
            }
        }

        .form-select {
            border-radius: 3px;
        }

        .form-select:disabled {
            background-color: #F2F2F2;
            border: 1.5px solid #E6E6E6;
        }

    }

    .area-btn {
        margin-top: 24px;

        @media (min-width: 992px) {
            width: 369px;

        }

        .btn-vermelho {
            .icones {
                margin-right: 8px;
            }
        }

        .desabilitado {
            opacity: .4;
            cursor: default;

            &:hover {
                background: #CC092F;
            }
        }


        .cashback-desabilitado {
            opacity: .4;
            cursor: default;
        }

        .fazer-login {
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: #4F4F4F;
            margin-top: 8px;
            text-align: center;
            transition: all ease-in-out .4s;
            margin-bottom: 32px;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }

            .c-vermelho {

                &:hover {
                    color: darken(#CC092F, 10%);
                }
            }
        }
    }
}

.sobre-oferta {
    color: #000000;
    font-size: 16px;
    line-height: 23px;

    @media (min-width: 992px) {
        line-height: 32px;
    }

    h4,h5,h6 {
        margin-top: 16px;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }

    ul {
        margin-top: 16px;
        margin-bottom: 32px;
        padding-left: 50px;
    }

    p{ margin: 0; }

    small {
        display: inline-block;
        font-size: 12px;
        line-height: 15px;
    }

    a {
        color: $vermelho;

        &:hover {
            color: darken(#CC092F, 10%);
        }
    }
}

.acards_sect {
    .carousel-cards {
        margin-top: 64px !important;
        margin-bottom: 50px !important;
    }
}

.btn-cashback {
    background: #FFFFFF;
    border: 2px dashed #D9D9D9;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    color: #262626 !important;
    cursor: text;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 22px;
    transition: 0.3s ease;
    position: relative;


    &.verde{
        color: #11A60F !important;
        border: 1.5px dashed #11A60F;
        background: #E6FFE5;
    }

    &.copiar-colar {
        border: 1.5px solid #11A60F;
        background-color: #E6FFE5;
        color: #000000;
    }
}

.error_box {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    background-color: rgba(204, 9, 47, 0.05);
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    margin-bottom: 16px;

    &_text {
        font-size: 16px;
        line-height: 120%;
        color: #CC092F;
        margin-left: 10px;
        margin-bottom: 0;
    }
}

.recuperar_text {
    font-size: 14px;
    line-height: 120%;
    color: #555658;
    margin-bottom: 18px;

    &:last-child {
        margin-bottom: 0;
    }

    &:first-of-type {
        margin-top: 24px;
    }
}

.inputs-codigo_holder {
    margin-bottom: 24px;

    .error_text {
        display: none;
        font-size: 14px;
        line-height: 120%;
        color: #CC092F;
        margin: 8px 0 0;
    }

    &.inputs-codigo_error {
        .form-control {
            border-color: #CC092F;
        }

        .error_text {
            display: block;
        }
    }
}

.inputs-codigo_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-control {
        width: 49px;
        max-width: 49px;
    }
}

.meu-perfil_sect {
    padding: 40px 0 80px;

    .perfil_intro {
        display: flex;
        align-items: flex-end;
        margin-bottom: 21px;

        @media(min-width: 992px) {
            margin-bottom: 32px;
        }

        .intro_title {
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            color: #000000;
            margin-bottom: 0;
            margin-right: 12px;
        }

        .intro_text {
            font-size: 18px;
            line-height: 120%;
            color: #6D6E71;
            margin-bottom: 0;
        }
    }

    .biometria-switch_holder {
        margin-bottom: 48px;

        .subtitle {
            margin-bottom: 9px;
        }

        .biometria-switch_wrapper {
            display: flex;
            align-items: center;

            .biometria_text {
                color: #4F4F4F;
                font-size: 13px;
                font-weight: 400;
                margin: 0 16px 0 0;
            }

            .biometria_switch {
                width: 40px;
                box-shadow: none;

                &:checked {
                    border-color: #CC092F;
                }

                &:focus, &:focus-within, &:active {
                    border: 1px solid;
                    border-color: #A2A2A5;
                }
            }
        }
    }

    .cashback_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: #000000;
        margin-bottom: 24px;

        @media(min-width: 992px) {
            margin-bottom: 48px;
        }

        .fw-400 {
            font-weight: 400;
        }
    }

    .subtitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 16px;
    }

    .cashback_box {
        width: 100%;
        padding: 24px;
        border: 2px solid #D5D6D7;
        border-radius: 10px;

        @media(min-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 29px 40px 27px 60px;
        }

        .box_right-side {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            @media(min-width: 992px) {
                flex-direction: row;
                margin-bottom: 0;
            }

            .icon-pix {
                width: 49px;
                margin-right: 16px;

                @media(min-width: 992px) {
                    width: initial;
                    margin-right: 23px;
                }
            }

            .pix_info {
                .text {
                    font-size: 16px;
                    color: #000000;
                    margin-bottom: 8px;
                    font-weight: 700;
                }

                .value {
                    color: #3C3D3E;
                    margin-bottom: 0px;
                    font-size: 14px;

                    @media (min-width: 992px) {
                        font-size: 18px;
                        margin-bottom: 8px;
                    }
                }

                .disclaimer {
                    color: #A2A2A5;
                    font-size: 12px;
                    margin-top: 0;

                    @media (min-width: 992px) {
                        font-size: 14px;
                        max-width: 280px;
                    }

                    @media (min-width: 1200px) {
                        max-width: 392px;
                    }

                }
            }

            .btn {
                @media(min-width: 992px) {
                    width: 267px;
                }
            }
        }

        .box_left-side {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row-reverse;
            margin-bottom: 27px;

            @media(min-width: 992px) {
                flex-direction: row;
                margin-bottom: 0;
            }

            .icon {
                width: 72px;
                margin-left: 22px;

                @media(min-width: 992px) {
                    width: initial;
                    margin-left: initial;
                    margin-right: 60px;
                }
            }

            .cashback_info {
                .text {
                    font-size: 16px;
                    line-height: 19px;
                    color: #000000;
                    margin-bottom: 4px;
                }

                .value {
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 49px;
                    color: #CC092F;
                    margin-bottom: 10px;
                }

                .disclaimer {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    line-height: 15px;
                    color: #A2A2A5;
                    margin-bottom: 0;

                    .icone-relogio {
                        margin-right: 8px;
                    }
                }
            }

        }

        .btn {
            width: 100%;
            @media(min-width: 992px) {
                width: 267px;
            }
        }
    }
}

.cashback-table_intro {
    margin: 24px 0;

    @media(min-width: 992px) {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 55px 0 24px;
    }

    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #000000;
        margin-bottom: 23px;

        @media(min-width: 992px) {
            font-size: 20px;
            margin-bottom: 0;
        }
    }
}

.powered_text {
    width: 100%;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    color: #000000;

    @media(min-width: 992px) {
        text-align: right;
    }
}

.favoritar-oferta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .icone-coracao-fav {
        font-size: 18px;
        color: #F30347;
    }

}

.favoritos_sect {
    margin-top: 40px;
    margin-bottom: 80px;


    .categoria-cards_wrapper {
        gap: 24px 0;

        .card {
            margin-bottom: 24px;

            @media(max-width: 991px) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.fale-conosco_content {
    padding: 32px 0 120px;

    @media(min-width: 992px) {
        padding: 40px 0 120px;
    }

    .text {
        font-size: 14px;
        line-height: 150%;
        color: #000000;

        &:last-of-type {
            margin-bottom: 0;
        }

        @media(min-width: 992px) {
              font-size: 16px;
        }

        .link-vermelho {
            @media(min-width: 992px) {
              font-size: 16px;
            }
        }
    }
}

.fale-conosco_form {
    margin-top: 48px;

    @media(min-width: 992px) {
        font-size: 48px;
    }
}

.sobre{
    font-size: 16px;
    line-height: 150%;
}

.politica{
    font-size: 14px;
    line-height: 140%;

    table {
        border: 1px solid black;
        @media (max-width: 768px) {
            min-width: 800px;
        }

        thead {
            background-color: #CC092F;
            color: white;

            th {
                text-align: center;
                min-width: 160px;
                border-right: 1px solid black;
            }
        }

        tbody {
            tr {
                td {
                    border-right: 1px solid black;
                }
            }
        }

        tr {
            border-bottom: 1px solid black;
        }
    }
}

.texto-faq {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 32px 0;

    @media (min-width: 992px) {
        font-size: 16px;
        line-height: 19px;
        margin-top: 40px;
        margin-bottom: 33px;
    }
}

.barra-cinza-faq {
    background: #FAFAFA;
    border-radius: 8px;
    width: 100%;
    padding: 18px 24px 21px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 40px;

    @media (min-width: 992px) {
        padding: 16px 18px 16px 24px;
        margin-top: 48px;
    }

    &-texto {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #000000;

        @media (min-width: 992px) {
            font-size: 24px;
            line-height: 29px;
        }
    }

    &-img {
        width: 32px;

        @media (min-width: 992px) {
            width: 40px;
        }
    }

    .icone-cashback {
        font-size: 24px;

        @media (min-width: 992px) {
            font-size: 31px;
        }
    }
}

.img-circuito-cultural {
    align-items: flex-start !important;
    padding: 60px 0 0 60px !important;

    .image_text {
        font-size: 40px !important;
        line-height: 52px !important;
    }
}

.left-circuito-cultural {
    padding-top: 40px !important;
    padding-bottom: 161px !important;
    @media (min-width: 992px) {
        padding-top: 105px !important;
        padding-bottom: 105px !important;
    }
}

.carousel-ingressos {
    overflow: visible !important;

    .swiper-slide {
        width: 282px;

        @media (min-width: 992px) {
            width: 364px;
        }
    }

    .swiper-button-next {
        top: inherit;
        bottom: -72px;
        left: 60px;
        background-color: #CC092F;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        rotate: 180deg;
        font-size: 24px;
        transition: all ease-in-out .4s;

        @media (min-width: 992px) {
            font-size: 21px;
            width: 52px;
            height: 52px;
            bottom: -95px;
            left: 68px;
        }

        &::after {
            content: '' !important;
        }

        &:hover {
            background-color: darken(#CC092F, 10%);
        }
    }

    .swiper-button-prev {
        top: inherit;
        bottom: -72px;
        background-color: #CC092F;
        border-radius: 50%;
        color: white;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        transition: all ease-in-out .4s;

        @media (min-width: 992px) {
            font-size: 21px;
            width: 52px;
            height: 52px;
            bottom: -95px;
        }

        &::after {
            content: '' !important;
        }

        &:hover {
            background-color: darken(#CC092F, 10%);
        }
    }
}

.ingressos-circuito_sect {
    background-color: black;
    overflow: hidden;
    padding-bottom: 148px;

    @media (min-width: 992px) {
        padding-bottom: 212px;
    }

    .expirado {
        opacity: .6 !important;
    }

    .btn-expirado {
        background-color: #E06B82;
        text-transform: uppercase;
        color: white !important;
        border: none;
    }

    .titulo-ingressos-sect {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: #FFFFFF;
        margin-bottom: 24px;

        @media (min-width: 992px) {
            margin-bottom: 51px;
        }
    }

    .card-ingresso {

        .card-img-ingresso {
            border-radius: 20px 20px 0px 0px;
            margin-bottom: -5px;
            position: relative;
            width: 100%;
            height: 136px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            @media (min-width: 992px) {
                height: 210px;
            }
        }

        .card-body-ingresso {
            position: relative;
        }

        .card-info-ingresso {
            background: #FFFFFF;
            border-radius: 5px 5px 0 0;
            margin-bottom: -5px;
            width: 100%;
            height: 100%;
            padding: 24px 37px 24px 24px;

            @media (min-width: 992px) {
                padding: 40px 32px 32px 27px;
            }
        }

        .titulo-ingresso {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 18px;

            @media (min-width: 992px) {
                font-size: 24px;
                line-height: 29px;
                margin-bottom: 24px;
            }
        }

        .localizacao-ingresso {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-decoration-line: underline;
            color: #D90037;
            margin-left: 8px;

            @media (min-width: 992px) {
                font-size: 18px;
                line-height: 22px;
                margin-left: 16px;
            }
        }

        .data-ingresso {
            font-size: 16px;
            line-height: 19px;
            color: #262626;
            margin-left: 8px;
            margin-bottom: 0;
            margin-right: 24px;
        }

        .hora-ingresso {
            font-size: 16px;
            line-height: 19px;
            color: #262626;
            margin-left: 8px;
            margin-bottom: 0;
        }

        .area-download {
            background: #2F2F2F;
            border-radius: 5px 5px 20px 20px;
            position: relative;
            padding: 24px;
            align-items: center;
            display: flex;
            flex-direction: column;

            @media (min-width: 992px) {
                padding: 32px 32px 32px 27px;
            }
        }

        .icone-download {
            margin-right: 8px;
        }

        .btn-vermelho {
            border-radius: 10px !important;
            width: 100% !important;
        }

        .detalhar {
            font-size: 14px;
            line-height: 17px;
            text-decoration-line: underline;
            color: #FFFFFF;
            transition: all ease-in-out .4s;
            margin-top: 16px;

            @media (min-width: 992px) {
                font-size: 16px;
                line-height: 19px;
                margin-top: 18px;
            }

            &:hover {
                text-decoration: none;
                color: darken(white, 10%);
            }
        }
    }


}



// page Detalhe de Evento
.detalhe-evento{
   margin-top: 40px;
   margin-bottom: 72px;
   display: none;

      @media (min-width:992px){
            display: flex;
      }

    .img{
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        border-radius: 20px;

        &.radius-reset{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .link-voltar{
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: #0061AA;
        margin-bottom: 24px;

        .icones{transform: rotate(180deg);}

    }

    &_info{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #4F4F4F;
        margin-top: 50px;
    }

    .ingresso{
        width: 100%;
        border-radius: 20px;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.14);

        &-info{
          padding: 48px 60px 58px 40px;
          position: relative;

          &::before{
            content: '';
            position: absolute;
            bottom: -20px;
            left: -1px;
            width: 23px;
            height: 42px;
            border-color: transparent;
            border-style: solid;
            border-width: 100%;
            border-radius:  0px 22px 22px 0px;
            background-color: #ededed;
          }
          &::after{
            content: '';
            position: absolute;
            bottom: -20px;
            right: -1px;
            width: 23px;
            height: 42px;

            border-color: transparent;
            border-style: solid;
            border-width: 100%;
            border-radius:  22px 0 0 22px;
            background-color: #ededed;
          }

            .titulo{
                font-weight: 600;
                font-size: 32px;
                line-height: 120%;
                margin-bottom: 24px;
            }

            .detalhes{
                display: flex;
                justify-content: space-between;
                margin-bottom: 28px;


                    &-link{
                        font-size: 14px;
                        line-height: 17px;
                        text-align: right;
                        text-decoration-line: underline;
                        color: #0697FF;
                    }
            }

            .data-hora{
                display: flex;
                align-items: center;
                gap: 18px;
                margin-bottom: 0;
            }

        }

        .map{
            display: flex;
            align-items: flex-start;
            gap: 16px;

            .endereco{
                &-titulo{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: #D90037;
                    margin-bottom: 4px;
                }
                &-subtitulo{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #4F4F4F;
                    margin-bottom: 0;
                }

            }
        }

        &-download {
            border-top: 3px dashed #262626;
            padding-top: 48px;
            padding: 48px 52px;

            .btn-download{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                background: #2B2B2B;
                color: white;
                text-decoration: none;
                font-weight: 600;
                border-radius: 5px;
                margin-bottom: 16px;
                padding: 16px;
                transition: all .4s ease;

                &:hover{
                    background-color: darken(#2B2B2B, 50%);
                }
            }
            .texto{
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: #4F4F4F;
                margin-bottom: 0;
            }
        }

        &.evento2{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .ingresso-info {
                padding:65px 48px 40px;

                &::before{display: none;}
                &::after{display: none;}

                .detalhes{
                    justify-content: flex-start;
                    align-items: flex-end;
                    margin-bottom: 24px;
                }
            }
            .ingresso-download {
                padding: 0 56px 65px 48px;
                border: 0;

                .btn-download{
                    margin-bottom: 32px;
                }
                .texto{
                    text-align: left;
                }
            }
        }
    }

    &.mobile{
        display: flex;
        background-color: black;
        position: relative;
        padding-bottom: 48px;

        @media (min-width:992px){
            display: none;
        }

        .btn-close{
            position: absolute;
            top: 24px;
            right: 24px;
            display: block;
            height: auto;
            border: 0;
            outline: none;
            background-color: transparent;
            width: initial;
            padding: 0;
            opacity: 1;

            &:focus{
                border: 0;
                outline: none;
                box-shadow: none;
            }

            img{
                max-width: 24px;

                @media (min-width: 380px){
                    max-width: initial;
                }
            }
        }

        .titulo{
            text-align: center;
            color: white;
            font-weight: 600;
            font-size: 20px;
            margin-top: 64px;
            margin-bottom: 72px;

            @media (min-width: 380px){
                font-size: 24px;
                line-height: 29px;
            }
        }

        .ingresso{
            background-color: white;
            border-radius: 0;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            position: relative;

            .ingresso-head{
                text-align: center;
                height: 210px;
                position: relative;

                @media (min-width:440px){height: 245px;}
                @media (min-width:500px){height: 290px;}
                @media (min-width:575px){height: 288px;}
                @media (min-width:768px){height: 400px;}

                .img-mobile{
                    position: absolute;
                    top: -48px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 10px;
                    box-shadow: 11px 11px 21px 9px rgba(87, 19, 19, 0.15);
                    width: calc(100% - 32px);
                    min-width: 296px;

                    @media (min-width: 380px){
                        min-width: 310px;
                    }
                }

                .titulo{
                    color: #2B2B2B;
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    //white-space: nowrap;
                    width: 100%;
                    margin: 0;
                }

                &::before{
                    content: '';
                    position: absolute;
                    bottom: -14px;
                    left: -14px;
                    width: 28px;
                    height: 28px;
                    background-color: black;
                    border-radius: 50%;
                }
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -14px;
                    right: -14px;
                    width: 28px;
                    height: 28px;
                    background-color: black;
                    border-radius: 50%;
                }
            }

            .ingresso-body{
                padding: 44px 16px;
                border-top: 3px dashed #262626;
                color: #2B2B2B;

                .list{
                    list-style-type: none;
                    padding: 0;
                    margin-bottom: 24px;

                    &-item{
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        & + .list-item{margin-top: 20px;}

                        .map{
                            gap: 12px;
                        }
                    }
                }
                .btn-download{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 16px;
                    gap: 10px;
                    background: #BA082B;
                    color: white;
                    border-radius: 5px;
                    margin-bottom: 16px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    text-decoration: none;
                    transition: all .4s ease;

                    &:hover{
                        background-color: darken(#BA082B, 4%);
                    }
                }
                .detalhes{
                    border-top: 1px solid rgba(43, 43, 43, 0.05);
                    border-bottom: 1px solid rgba(43, 43, 43, 0.05);
                    padding-top: 16px;
                    padding-bottom: 12px;

                    &-titulo{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 8px;
                    }
                    &-subtitulo{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 0;
                    }
                    &-texto{
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                }

                .sobre{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;

                    &-titulo{
                        margin-top: 16px;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }

            .circle-rodape{
                position: absolute;
                left: 0;
                bottom: -7px;
                width: 100%;
                height: 20px;
                background-image: radial-gradient(#000000 12px, transparent 12px);
                background-size: 28px 28px;
                background-color: transparent;
            }
        }
    }
}

.btn-voltar{
    display: block;
    font-family: 'bradescosans-regular',sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #555658;
    margin-top: 24px;
    margin-bottom: 16px;

    @include min992{
        margin-top: 40px;
        margin-bottom: 24px;
    }
}

.box-topo{
    color: #191919;
    margin-bottom: 24px;

    @include min992{margin-bottom: 40px;}

    .printer-download{
        opacity: 1;
        transition: all 0.3s ease;

        &:hover{
          opacity: .3;
        }

    }

    &-titulo{
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 0;
    }

    .nomes{
        margin-bottom: 24px;

        @include min992{
            margin-bottom: 32px;
        }

        .titulo-nome{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 8px;
        }
        .subtitulo{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0;
        }
    }
        .voucher {
            padding: 32px;
            background: #F2F2F2;

            @include min992 {
                padding: 24px 32px 32px;
            }

            .texto-codigo {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
            }

            .texto-validade {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }

            .titulo-voucher {
                font-weight: 700;
                font-size: 40px;
                line-height: 49px;
                margin-bottom: 0;
            }

            .texto-utilizar {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 0;
            }
            .texto{
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0;
            }
        }

}
.info-detalhes{
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    .info-detalhes-titulo{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 16px;
    }
    .texto-termos{
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
    }

    .btn{
        width: 100%;
        margin-top: 40px;
        margin-bottom: 80px;

        @include min992{max-width:340px ;}
    }
}

.link-compartilhar {
    text-decoration: none;
    color: black;
}

.w-338 {
    @media (min-width: 992px) {
        max-width: 338px;
    }
}

.campanha-cards_section {
    overflow: hidden;
    margin-bottom: 46px;

    @media(min-width: 992px) {
        overflow: initial;
        margin-bottom: 64px;
    }

    .title {
        font-family: 'bradescosans-bold';
        color: #000;
        font-size: 18px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 32px;

        @media (min-width: 992px) {
            font-size: 24px;
        }
    }

    &.last_section {
        margin-bottom: 100px;

        @media (min-width: 992px) {
            margin-bottom: 155px;
        }
    }
}


.w-145 {
    @media (min-width: 992px) {
        max-width: 145px;
    }
}

.w-215 {
    @media (min-width: 992px) {
        max-width: 215px;
    }
}

.sect-parceios-a-z{
    margin-top: 40px;
    margin-bottom: 40px;

    .lista-parceiros{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 12px;
        margin-top: 32px;
        margin-bottom: 48px;

        &_btn{
            padding: 0;
            background-color: transparent;
            color: black;
            font-size: 20px;
            line-height: 120%;
            border: none;

            &.active{
                font-family: 'bradescosans-bold';
                color: #CC092F;
            }

            &:hover{
                color: #CC092F;
            }
        }
    }


    .lista-parceiros-conteudo{

        li + li {
            margin-top: 16px;
        }

        &_link{
            color: #262626;
            font-size: 18px;
            text-decoration: none;

            &:hover{
                color: #CC092F;
                text-decoration: underline;
            }
        }
    }
}

.bg-erro {
    width: 100%;
    height: 40px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.erro-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 0 158px 0;

    @media (min-width: 992px) {
        padding: 81px 0 140px 0;
    }

    .texto-erro {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.96px;
        color: #000;
        margin-bottom: 0;
        max-width: 260px;
        text-align: center;

        @media (min-width: 992px) {
            font-size: 24px;
            max-width: 575px;
        }
    }

    .mb-50 {
        margin-bottom: -20px !important;

        @media (min-width: 992px) {
            margin-bottom: -50px !important;
        }

    }

    .numero-erro {
        font-size: 96px;
        letter-spacing: 3.84px;
        font-weight: 600;
        color: #CC092F;
        margin-bottom: 0;

        @media (min-width: 992px) {
            font-size: 160px;
            letter-spacing: 6.4px;
        }
    }

    .btn-outline-vermelho {
        margin-top: 56px;

        @media (min-width: 992px) {
            margin-top: 72px;
        }
    }
}

.pagina-nao-encontrada {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 122px 0 162px 0;

    @media (min-width: 992px) {
        padding: 120px 0 194px 0;
    }

    .img-pag {
        width: 56px;
        height: 56px;
        margin-bottom: 16px;

        @media (min-width: 992px) {
            width: 64px;
            height: 64px;
            margin-bottom: 24px;
        }
    }

    .texto-pag{
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.96px;
        color: #000;
        margin-bottom: 0;
        text-align: center;

        @media (min-width: 992px) {
            font-size: 24px;
        }
    }

    .btn-outline-vermelho {
        margin-top: 40px;
    }

}

.nps {
    position: fixed;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    z-index: 100;
    border-radius: 10px 10px 0px 0px;
    background-color: #CC092F;
    padding: 13.5px 48.6px;
    height: 50px;
    transition: all ease-in-out .4s;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 329px;
    animation: aparecer 2.5s ease-in-out forwards;

    @media (min-width: 992px) {
        padding: 14px 24px 12px 24px;
        right: 30px;
        transform: translateX(0);
        width: 235px;
    }

    @keyframes aparecer {
        0% {
          transform: translate(50%, 80px);
        }
        100% {
          transform: translate(50%, 0);
        }
    }

    @media (min-width: 992px) {
        @keyframes aparecer {
            0% {
              transform: translate(0px, 80px);
            }
            100% {
              transform: translate(0px, 0);
            }
        }
    }


    &:hover {
        height: 60px;
        padding-bottom: 22px;
    }

    &_texto {
        margin-bottom: 0;
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.72px;

        @media (min-width: 992px) {
            font-size: 14px;
            letter-spacing: 0.56px;
        }
    }

    .star {
        margin-right: 8px;
    }

}

.bg-degrade {
    width: 100%;
    height: 40px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.00) 100%);
}


@keyframes slide-in {
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    to {
        transform: translateY(100%);
    }
}

.cookies_bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 101;
    width: 100%;
    padding: 24px 0;
    background-color: white;
    box-shadow: 0px -4px 16px 0px rgba(25, 25, 25, 0.08);
    transform: translateY(100%);
    animation: slide-in 1s ease forwards;
    animation-delay: 0.4s;

    .container {
        @media(min-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .cookies_bar_text {
            color: #000;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 24px;

            @media(min-width: 992px) {
                text-align: initial;
                margin-bottom: 0;
                margin-right: 54px;
                font-size: 16px;
            }
        }

        .btns_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                width: 163px;
                min-width: 163px;
                height: 51px;
                font-size: 16px;


                &:last-child {
                    margin-left: 8px;
                }

                &:first-child {
                    margin-right: 8px;
                }
            }
        }
    }

    &.out {
        animation: slide-in 1s ease forwards, slide-out 0.8s ease forwards;
    }
}

.saldo-clube {

    .area-valor {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        @media (min-width: 992px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 8px;
        }
    }

    .texto-saldo {
        font-size: 16px;
        color: #000;
        margin-bottom: 0;

        @media (min-width: 992px) {
            margin-bottom: 4px;
        }
    }

    .texto-valor {
        color: #CC092F;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 0;
    }

    .area-atualizado {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        @media (min-width: 992px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 40px;
        }
    }

    .icone-clock {
        margin-right: 8px;
    }

    .texto-atualizado {
        color: #A2A2A5;
        font-size: 12px;
        margin-bottom: 0;
    }

    .link-saldo {
        color: #CC092F;
        font-size: 14px;
        text-decoration: underline;
        font-weight: 500;
        transition: all ease-in-out .4s;

        &:hover {
            text-decoration: none;
        }

    }
}

// --------------------------------------------- //

.modal-circuito-onboarding {

    .modal-dialog {
        max-width: 366px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 992px) {
            min-width: 706px;
            max-width: 706px;
        }
    }

    .modal-content {
        border-radius: 30px;
        color: white;
        background-color: #D90037;
    }

    .modal-header {
        position: relative;
        padding: 0;
        border: 0;
        overflow: hidden;
        border-radius: 30px 30px 0 0;
        background-color: #D90037;

        .modal_img {
            max-width: 100%;
        }

        .modal_img_mobile {
            @media(min-width: 992px) {
                display: none;
            }
        }

        .modal_img_desktop {
            display: none;

            @media(min-width: 992px) {
                display: block;
            }
        }

        .close {
            position: absolute;
            top: 15px;
            right: 20px;
            background-color: transparent;
            outline: none;
            border: 0;
            transition: 0.3s ease;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .modal-body {
        padding: 50px 21px 100px;
        margin: 0 auto;
        background-color: #D90037;
        border-radius: 0 0 30px 30px;

        @media(min-width: 992px) {
            padding: 30px 30px 100px;

        }

        .title {
            margin: 0 auto;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            max-width: 287px;

            @media(min-width: 992px) {
                font-size: 24px;
                max-width: 465px;
            }
        }
    }

    .circuito-onboarding_carousel {
        max-width: 100%;
        overflow: visible;

        .swiper-button-next, .swiper-button-prev {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background-color: white;
            transition: 0.4s ease;

            @media(max-width: 991px) {
                bottom: 15px;
                top: initial;
            }

            @media(min-width: 992px) {
                width: 52px;
                height: 52px;
            }

            &::after {
                font-family: 'icones-clubevantagens' !important;
                font-size: inherit;
                font-style: normal !important;
                font-weight: normal !important;
                color: inherit;
                font-display: swap;
                color: #4F4F4F;
                font-weight: 700;
            }
        }

        .swiper-button-next {
            right: 10px;

            @media(min-width: 992px) {
               right: -80px;
            }

            &::after {
                content: "\e927"
            }

            .next_text {
                display: none;
                color: #4F4F4F;
                font-size: 18px;
                font-weight: 700;
                margin-right: 10px;

                @media(min-width: 992px) {
                    display: none;
                }
            }

            &.swiper-button-disabled {
                width: 152px;
                border-radius: 35px;
                opacity: 1;

                @media(min-width: 992px) {
                    width: 52px;
                    border-radius: 50%;
                    opacity: 0;
                }

                .next_text {
                    display: block;

                    @media(min-width: 992px) {
                        display: none;
                    }
                }

                &::after {
                    color: #4F4F4F;
                }
            }
        }

        .swiper-button-prev {
            left: 10px;

            @media(min-width: 992px) {
                left: -80px;
            }

            &::after {
                content: "\e926"
            }

            &.swiper-button-disabled {
                opacity: 0;
                color: #868686;
            }
        }

        .swiper-pagination {
            bottom: 196px;

            @media(min-width: 992px) {
                bottom: 20px;
            }

            .swiper-pagination-bullet {
                background-color: #DCDCDC;
                width: 7px;
                height: 7px;
                opacity: 1;
                transition: 0.3s ease;

                @media(min-width: 992px) {
                    width: 10px;
                    height: 10px;
                }

                &-active {
                    width: 43px;
                    background-color: white;
                    border-radius: 30px;

                    @media(min-width: 992px) {
                        width: 43px;
                    }
                }
            }
        }
    }
  }

.login-pix {
    margin-bottom: 32px;

    .filtro_radio-modal {      
        margin-top: 24px;

        @media (min-width: 992px) {
            margin-right: 40px;
            margin-top: 0px;
        }

        .input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;   
            
            &:checked {
                ~ .checkmark {                  
                    border-color: #A2A2A5;
                    color: #CC092F;  
                    
                    .mark_holder {
                        .mark {
                            border-color: #A2A2A5;
                            
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .checkmark {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;               
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;        
            color: #A2A2A5;     
            white-space: nowrap;
            transition: 0.4s ease;

            &:hover {
                border-color: #A2A2A5;
            }

            .mark_holder {
                display: flex;
                align-items: center;

                .mark {
                    position: relative;
                    width: 14px;
                    min-width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 1px solid #A2A2A5;
                    background-color: transparent;
                    margin-right: 8px;

                    &::after {                        
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        width: 8px;
                        min-width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #CC092F;
                        transition: 0.4s ease;
                        opacity: 0;
                    }
                }
            }       
            
            &_text {
                font-size: 14px;
                color: #000;
                @media (min-width: 768px) {
                    font-size: 16px;
                }

            }
        }
    }

    .group-email {
        .input-form-group {
            margin-bottom: 16px;

            &:last-of-type {
                margin-bottom: 0;
            }

            @media (min-width: 768px) {
                margin-right: 24px;
                margin-bottom: 0;
                min-width: 275px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .btn-vermelho {
        width: 276px;
        margin-top: 32px;
        font-weight: 500;
    }
}

.tooltip-login {
    position: absolute;
    top: 15px;
    right: 45px;
}

.texto-input-senha {
    color: #6D6E71;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 24px;
    margin-top: 8px;
}

.seguros_sect {
    overflow: hidden;
    padding-top: 58px;
    padding-bottom: 4px;

    .titulo {
        font-family: 'bradescosans-regular',sans-serif;
    }

    .seguros_carousel {
        overflow: visible;
        margin-top: 40px;

        @media(min-width: 768px){
            margin-top: 32px;
        }
    }

    .seguro_card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 10px;
        border: 1px solid #EDEDED;
        background: #FFF;
        padding: 24px 24px 24px 12px;

        .icon {
            max-height: 44px;
        }

        .card_title {
            flex: 1;
            color: #CC092F;         
            font-size: 20px;
            font-style: normal;           
            line-height: 120%;
            margin: 16px 0 24px;
            text-wrap: nowrap;
        }

        .btn {
            width: 100%;
        }
    }
}

.icone-externo {
    margin-left: 8px;
}

.btn-ofertas {
    .btn-cashback {
        margin-bottom: 16px;
    }

    .btn {
        height: 54px;
    }

}

.form-detalhe-oferta {
    &::before {
        display: none;
    }
}

.novos-cards_sect {
    overflow: hidden;
    padding: 56px 0 0 0;
    position: relative;

    @media (min-width: 992px) {
        padding: 0;
        overflow: initial;
    }

    .sticky {
        @media (min-width: 992px) {
            overflow: hidden;
            position: sticky;
            top: 0;
            height: 100vh;
            display: flex;
            align-items: center;
        }
    }

    .novos-cards-area {
        @media (min-width: 992px) {
            display: flex;
        }
    }

    .intro {
        min-width: 348px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 31px;

        @media (min-width: 992px) {
            min-width: 360px;
            justify-content: center;
            margin-right: 60px;
            margin-bottom: 0;
        }

        .titulo {
            color: #262626;
            margin-bottom: 26px;
            font-family: 'bradescosans-regular',sans-serif;
            text-align: center;
            font-size: 32px;
            font-weight: 400;
            line-height: 120%;

            @media (min-width: 992px) {
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                text-align: left;
                line-height: 120%;
                margin-bottom: 52px;
            }
        }

        .logo {
            max-width: 245px;

            @media (min-width: 992px) {
                max-width: 326px;
            }

            &.logo-mais-saude{
                width: 214px;
                margin-bottom: 24px;

                @media (min-width: 992px){
                    width: initial;
                    margin-bottom: 0;
                }
            }
        }

        

        .c-vermelho {
            color: #D90037;
            font-weight: 700;
        }

        &_box{
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
              padding: 0 24px;
              color: #262626;

              @media (min-width: 992px){
                display: none;
              }

              .titulo{
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 16px;
              }
              .desc{
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 32px;
              }
        }
    }

    .card-area {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0 12px;
        }

        @media (min-width: 992px) {
            display: flex;
            flex-direction: row;
        }
    }

    .card-novo {
        height: 270px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 342px;
        color: #FFF;
        padding: 0 26px 20px 20px;
        position: relative;
        overflow: hidden;
        transition: all ease .3s;
        border-radius: 10px;
        margin-bottom: 24px;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        opacity: 0;
        translate: 100px;
        cursor: pointer;

        @media (min-width: 768px) {
            width: 100%;

            &:nth-child(3) {
                margin-bottom: 0;
            }
        }

        @media (min-width: 992px) {
            aspect-ratio: 1.24/1;
            min-height: 400px;
            height: 65.5vh;
            padding: 0;
            border-radius: 20px;
            margin-bottom: 0;
            margin-right: 24px;
        }

        &:last-of-type {
            margin-bottom: 0;

            @media (min-width: 992px) {
                margin-right: 0;
            }
        }

        &:hover {
            @media (min-width: 992px) {
                .borda {
                    border: 6px solid #CC092F;
                }
            }
            @media (min-width: 992px) {
                .borda.azul {
                    border: 6px solid #006CA9;
                }
            }
        }

        &.animate-in{
            opacity: 1;
            translate: 0;
            transition-duration: 1s;
        }

        .iconeplay{
            width: 80px;
            height: 80px;
            position: absolute;
            top: calc((100%/2) - 40px );
            left: 50%;
            transform: translateX(-50%);

            @media (min-width: 992px) {
            width: 120px;
               height: 120px;
               top: calc((100%/2) - 60px );
            }

        }

        .qrcode-card{
            width: 80px;
            position: absolute;
            top: 20px;
            left: 20px;

            @media (min-width: 992px){
              width: 112px;
              top: 29px;
              left: 32px;
            }
        }


    }

    .borda {
        @media (min-width: 992px) {
            border-radius: 20px;
            border: 6px solid transparent;
            transition: all ease .3s;
            height: 100%;
            position: relative;
            z-index: 5;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    .info {
        position: relative;
        z-index: 5;

        @media (min-width: 992px) {
            padding: 0 24px 32px;
        }

        @media (min-width: 1600px) {
            padding: 0 44px 44px;
        }
    }

    .tag {
        font-size: 12px;
        font-weight: 500;
        padding: 0 20px;
        border-radius: 40px;
        border: 2px solid #FFF;
        background: rgba(255, 255, 255, 0.20);
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 31px;
        width: fit-content;

        @media (min-width: 992px) {
            margin-bottom: 16px;
            height: 33px;
            padding: 0 16px;
            font-size: 14px;
            border-radius: 40px;
        }

        @media (min-width: 1600px){
            font-size: 16px;
            padding: 0 24px;
            height: 39px;
        }
    }

    .titulo-card {
        font-size: 20px;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 8px;

        @media (min-width: 992px) {
            font-size: 32px;
            margin-bottom: 16px;
        }

        @media (min-width: 1600px)  {
            font-size: 40px;
        }
    }

    .ofertas {
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        margin-bottom: 0;
        font-size: 16px;

        @media (min-width: 992px) {
            font-size: 18px;
        }

        @media (min-width: 1200px) and (max-height: 500px) {
            font-size: 16px;
        }
    }

    .gradiente {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
        height: 153px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;

        @media (min-width: 992px) {
            height: 335px;
        }
    }
}

.c-azul-100{
    color: #006CA9 !important;
}

.modal-ver-cupom{
    padding: 32px;

    .modal-dialog{
        max-width: 433px;
    }
    .modal-body{
        padding: 32px;
    }

    &_header{
        display: flex;
        gap: 16px;
        margin-bottom: 32px;

        .logo-baw{
            width: 72px;
            height: 72px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: white;
              border-radius: 50%;
              border: 1.5px solid  #BCBCBE;
            .img{
              height: 10px; 
            }
        }

        .subtitulo{
            color: black;
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 0;
        }
    }
}

.area-btn-v3 {
    .btn-copiar-colar {
        border: 1.5px solid #11A60F;
        background-color: #E6FFE5;
        color: #000000;
        position: relative;
        text-decoration: none;
        transition: all ease .3s;
        padding: 16px 140px 16px 20px;
        cursor: pointer;

        &:hover {
            border: 1.5px solid #037E00;
            .copiar-colar {
                background-color: #037E00;
            }
        }
    
        .copiar-colar {
            background-color: #03A100;
            padding: 10px 22px;
            text-align: center;
            height: 56px;
            border-radius: 0px 10px 10px 0px;
            color: #F7F7F7;
            font-size: 14px;
            font-weight: 700;
            line-height: normal;
            width: 120px;
            position: absolute;
            right: 0;
        }
    }

    .ver-desabilitado {
        background-color: #A2A2A5;
        color: white;
        opacity: .2;
        cursor: initial;

        &:hover {
            background-color: #A2A2A5
        }
    }
}


.codigo_text {
    color: #CC092F;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    padding: 0;

    @media (min-width: 992px) {
        font-size: 22px;
    }

    &:focus {
        color: #CC092F;
    }
}

.sucesso_autenticacao {
    .sucesso-left-side {
        .sucesso_wrapper {
            height: calc(100vh - 170px);
            min-height: 350px;
            display: flex;
            flex-direction: column;

            @media (min-width: 992px) {
                height: initial;
                min-height: initial;
            }

            &-info {
                margin: auto;
            }

            .icone {
                width: 128px;
                margin-bottom: 24px;
        
                @media (min-width: 992px) {
                    margin-bottom: 14px;
                    width: 48px;
                }
            }
            .success_title {
                font-size: 24px;
                margin-bottom: 32px;
            }

            .btn-vermelho {
                width: 100%;
                
                @media (min-width: 992px) {
                    width: 369px;
                }
            }
        }
    }
}

.mt-40-cadastro {
    @media (min-width: 992px) {
        margin-top: 40px;
    }
}

.z-10{
    z-index: 10 !important;
}

.login-info-box {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    border-radius: 8px;
   
    border: 1px solid #BCBCBE;

    margin: 24px 0;

    &_icon-holder {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 4px;
        width: 32px;
        height: 32px;
        min-width: 32px;

        background-color: #FEEBEF;
    }

    &_text {
        color: #000;      
        font-size: 13px;   
        line-height: 150%;

        margin-bottom: 0;
    }
}